<template>
    <v-dialog
        :key="pageKey"
        persistent
        v-model="editDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="500px"
        min-width="500px"
        max-width="600px"
    >
        <v-card>
            <v-card-title class="success white--text">
                <span>{{ updateMode == false ? $t('message.addDeliveryAddress') : $t('message.updateDeliveryAddress') + ": " + deliveryAddress }}</span>
            </v-card-title>
            <v-card-text>
                <v-overlay
                    :value="loading.get"
                    absolute
                    opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                color="primary"
                                indeterminate
                                size="40"
                                width="6"
                            ></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-form lazy-validation ref="deliveryAddressForm" v-model="validForm">
                    <v-expansion-panels
                        accordion
                        class="pt-3"
                        v-model="panel"
                        multiple
                    >
                        <v-expansion-panel>
                            <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.filterConditions') }}</v-expansion-panel-header>
                            <v-expansion-panel-content class="dense-inputs">
                                <v-layout row pt-3>
                                    <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.destination') }}</v-flex>
                                    <v-flex xs6 lg8 md6 py-1>
                                        <v-autocomplete
                                                :items=allCountries
                                                clearable
                                                dense
                                                item-text="Country.name"
                                                item-value="Country.id"
                                                hide-details="auto"
                                                solo
                                                v-model="SdaAddress__destination_id"
                                        />
                                    </v-flex>
                                </v-layout>
                                <v-layout row>
                                    <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.destinationPort') }}</v-flex>
                                    <v-flex xs6 lg8 md6 py-1>
                                        <v-autocomplete
                                                :items=allShippingDischargePorts
                                                clearable
                                                dense
                                                item-text="Shippingport.title"
                                                item-value="Shippingport.id"
                                                hide-details="auto"
                                                solo
                                                v-model="SdaAddress__shippingport_id"
                                        >
                                            <template v-slot:selection="data">
                                                {{ data.item.Country.name ? data.item.Shippingport.title + ' - ' + data.item.Country.name : data.item.Shippingport.title }}
                                            </template>
                                            <template v-slot:item="data">
                                                {{ data.item.Country.name ? data.item.Shippingport.title + ' - ' + data.item.Country.name : data.item.Shippingport.title }}
                                            </template>
                                        </v-autocomplete>
                                    </v-flex>
                                </v-layout>
                                <v-layout row>
                                    <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.salesType') }}</v-flex>
                                    <v-flex xs6 lg8 md6 py-1>
                                        <v-select
                                                :items=salesTypes
                                                clearable
                                                dense
                                                hide-details="auto"
                                                item-text="Salestype.title"
                                                item-value="Salestype.id"
                                                solo
                                                v-model="SdaAddress__salestype_id"
                                        />
                                    </v-flex>
                                </v-layout>
                                <v-layout row>
                                    <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.buyer') }}</v-flex>
                                    <v-flex xs6 lg8 md6 py-1>
                                        <v-autocomplete
                                                :items=allBuyers
                                                clearable
                                                dense
                                                hide-details="auto"
                                                item-text="Customer.title"
                                                item-value="Customer.id"
                                                solo
                                                v-model="SdaAddress__customer_id"
                                        >
                                            <template v-slot:selection="{ item }">
                                                <div>{{ item.Customer.otsname != null ? item.Customer.otsname : item.Customer.title }}</div>
                                            </template>
                                        </v-autocomplete>
                                    </v-flex>
                                </v-layout>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.addressInfo') }}</v-expansion-panel-header>
                            <v-expansion-panel-content class="dense-inputs">
                                <v-layout row pt-3>
                                    <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.appicDescription') }}</v-flex>
                                    <v-flex xs6 lg8 md6 py-1>
                                        <v-text-field
                                                :rules="[...validationRules.required, ...validationRules.plainText]"
                                                :value="SdaAddress__description"
                                                autocomplete="off"
                                                class="force-text-left"
                                                clearable
                                                dense
                                                hide-details="auto"
                                                solo
                                                @change="SdaAddress__description = $event"
                                        />
                                    </v-flex>
                                </v-layout>
                                <v-layout row>
                                    <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.agent') }}</v-flex>
                                    <v-flex xs6 lg8 md6 py-1>
                                        <v-lazy>
                                            <v-textarea
                                                    :rules="[...validationRules.required, ...validationRules.plainTextWithNumbers]"
                                                    :value="SdaAddress__agent"
                                                    auto-grow
                                                    class="elevation-0 pt-3"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    rows="1"
                                                    @change="SdaAddress__agent = $event"
                                            />
                                        </v-lazy>
                                    </v-flex>
                                </v-layout>
                                <v-layout row>
                                    <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.street') }}</v-flex>
                                    <v-flex xs6 lg8 md6 py-1>
                                        <v-lazy>
                                            <v-textarea
                                                    :rules="[...validationRules.required, ...validationRules.plainTextWithNumbers]"
                                                    :value="SdaAddress__street"
                                                    auto-grow
                                                    class="elevation-0 pt-3"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    rows="1"
                                                    @change="SdaAddress__street = $event"
                                            />
                                        </v-lazy>
                                    </v-flex>
                                </v-layout>
                                <v-layout row>
                                    <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.building') }}</v-flex>
                                    <v-flex xs6 lg8 md6 py-1>
                                        <v-lazy>
                                            <v-textarea
                                                    :rules="[...validationRules.required, ...validationRules.plainTextWithNumbers]"
                                                    :value="SdaAddress__building"
                                                    auto-grow
                                                    class="elevation-0 pt-3"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    rows="1"
                                                    @change="SdaAddress__building = $event"
                                            />
                                        </v-lazy>
                                    </v-flex>
                                </v-layout>
                                <v-layout row>
                                    <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.city') }}</v-flex>
                                    <v-flex xs6 lg8 md6 py-1>
                                        <v-text-field
                                                :rules="[...validationRules.required, ...validationRules.plainText]"
                                                :value="SdaAddress__city"
                                                autocomplete="off"
                                                class="force-text-left"
                                                clearable
                                                dense
                                                hide-details="auto"
                                                solo
                                                @change="SdaAddress__city = $event"
                                        />
                                    </v-flex>
                                </v-layout>
                                <v-layout row>
                                    <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.postcode') }}</v-flex>
                                    <v-flex xs6 lg8 md6 py-1>
                                        <v-text-field
                                                :rules="[...validationRules.required, ...validationRules.plainTextWithNumbers]"
                                                :value="SdaAddress__postcode"
                                                autocomplete="off"
                                                class="force-text-left"
                                                clearable
                                                dense
                                                hide-details="auto"
                                                solo
                                                @change="SdaAddress__postcode = $event"
                                        />
                                    </v-flex>
                                </v-layout>
                                <v-layout row>
                                    <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.country') }}</v-flex>
                                    <v-flex xs6 lg8 md6 py-1>
                                        <v-autocomplete
                                                :rules="[...validationRules.required]"
                                                :items=allCountries
                                                clearable
                                                dense
                                                item-text="Country.name"
                                                item-value="Country.name"
                                                hide-details="auto"
                                                solo
                                                v-model="SdaAddress__country"
                                        />
                                    </v-flex>
                                </v-layout>
                                <v-layout row>
                                    <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.attention') }}</v-flex>
                                    <v-flex xs6 lg8 md6 py-1>
                                        <v-text-field
                                                :rules="[...validationRules.required, ...validationRules.plainText]"
                                                :value="SdaAddress__attention"
                                                autocomplete="off"
                                                class="force-text-left"
                                                clearable
                                                dense
                                                hide-details="auto"
                                                solo
                                                @change="SdaAddress__attention = $event"
                                        />
                                    </v-flex>
                                </v-layout>
                                <v-layout row>
                                    <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.phone') }}</v-flex>
                                    <v-flex xs6 lg8 md6 py-1>
                                        <v-text-field
                                                :rules="[...validationRules.required, ...validationRules.phoneNumber]"
                                                :value="SdaAddress__phone"
                                                autocomplete="off"
                                                class="force-text-left"
                                                clearable
                                                dense
                                                hide-details="auto"
                                                solo
                                                @change="SdaAddress__phone = $event"
                                        />
                                    </v-flex>
                                </v-layout>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-form>
            </v-card-text>
            <v-card-actions class="pt-3 pl-6">
                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                        :loading="loading.add"
                        color="info"
                        small
                        @click="addDeliveryAddress()"
                        v-if="updateMode == false"
                >{{ $t('message.add') }}</v-btn>
                <v-btn
                        :loading="loading.save"
                        color="info"
                        small
                        @click="saveDeliveryAddress()"
                        v-if="updateMode == true"
                >{{ $t('message.save') }}</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "AddDeliveryAddress",
    props: ['deliveryAddressId','deliveryAddress','dialog','updateMode','dialog-closed','update-done','create-done','pageKey'],
    data() {
        return {
            dialogs: {
                error: false,
                error_message: ""
            },
            edit_dialog: false,
            loading: {
                add: false,
                get: false,
                save: false
            },
            panel: [0,1],
            // rules: {
            //     agent: v => !!v || this.$t('message.required'),
            //     attention: v => !!v || this.$t('message.required'),
            //     city: v => !!v || this.$t('message.required'),
            //     country: v => !!v || this.$t('message.required'),
            //     description: v => !!v || this.$t('message.required'),
            //     phone: v => !!v || this.$t('message.required'),
            //     postcode: v => !!v || this.$t('message.required'),
            //     street: v => !!v || this.$t('message.required')
            // },
            validForm: true
        }
    },
    computed: {
        statePrefix() {
            return this.updateMode ? 'update' : 'current'
        },
        ...mapFieldsPrefixed('sdaaddress', {
            SdaAddress__id: 'Species.id',
            SdaAddress__agent: 'SdaAddress.agent',
            SdaAddress__attention: 'SdaAddress.attention',
            SdaAddress__building: 'SdaAddress.building',
            SdaAddress__city: 'SdaAddress.city',
            SdaAddress__country: 'SdaAddress.country',
            SdaAddress__customer_id: 'SdaAddress.customer_id',
            SdaAddress__description: 'SdaAddress.description',
            SdaAddress__destination_id: 'SdaAddress.destination_id',
            SdaAddress__phone: 'SdaAddress.phone',
            SdaAddress__postcode: 'SdaAddress.postcode',
            SdaAddress__remarks: 'SdaAddress.remarks',
            SdaAddress__salestype_id: 'SdaAddress.salestype_id',
            SdaAddress__shippingport_id: 'SdaAddress.shippingport_id',
            SdaAddress__street: 'SdaAddress.street'

        }, 'statePrefix'),
        ...mapGetters('buyer',{
            allBuyers: 'allBuyers'
        }),
        ...mapGetters('country',{
            allCountries: 'allCountries'
        }),
        ...mapGetters('shippingport', {
            allShippingDischargePorts: 'allShippingDischargePorts'
        }),
        ...mapGetters([
            'salesTypes',
            'validationRules'
        ]),
        editDialog: {
            get() {
                return this.edit_dialog;
            },
            set(value){
                this.edit_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        }
    },
    methods: {
        ...mapActions('sdaaddress', {
            createDeliveryAddress: 'createDeliveryAddress',
            getDeliveryAddressToUpdateById: 'getDeliveryAddressToUpdateById',
            resetCurrentState: 'resetCurrentState',
            resetDeliveryAddressToUpdate: 'resetDeliveryAddressToUpdate',
            updateDeliveryAddress: 'updateDeliveryAddress'
        }),
        ...mapActions('buyer', {
            resetAllBuyers: 'resetAllBuyers',
            getAllActiveBuyers: 'getAllActiveBuyers'
        }),
        ...mapActions('country',{
            getAllCountries: 'getAllCountries'
        }),
        ...mapActions('shippingport', {
            getAllShippingPorts: 'getAllShippingPorts',
        }),
        addDeliveryAddress() {
            if (this.$refs.deliveryAddressForm.validate()) {
                this.loading.add = true
                this.createDeliveryAddress()
                    .then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.deliveryAddressAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.add = false
                            this.$emit('create-done')
                            this.editDialog = false
                        } else {
                            this.$toast.error(this.$t('message.errors.deliveryAddressNotAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.add = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.deliveryAddressNotAdded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.add = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.deliveryAddressNotAdded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        saveDeliveryAddress() {
            if (this.$refs.deliveryAddressForm.validate()) {
                this.loading.save = true
                this.updateDeliveryAddress()
                    .then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.deliveryAddressUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.save = false
                            this.$emit('update-done')
                        } else {
                            this.$toast.error(this.$t('message.errors.deliveryAddressNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.save = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.deliveryAddressNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.deliveryAddressNotUpdated'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        }
    },
    watch: {
        dialog(value) {
            if(value == true){
                if(this.updateMode == true) {
                    this.loading.get = true
                    this.getDeliveryAddressToUpdateById(this.deliveryAddressId)
                        .then( () => {
                            this.loading.get = false
                        })
                } else {
                    this.resetCurrentState()
                }
            } else {
                if(this.updateMode == true) {
                    this.resetDeliveryAddressToUpdate()
                } else {
                    this.resetCurrentState()
                }
            }
            this.edit_dialog = value
        }
    },
    mounted() {
        if(this.allCountries.length == 0) this.getAllCountries()
        if(this.allShippingDischargePorts.length == 0) this.getAllShippingPorts()
        if(this.allBuyers.length == 0) this.getAllActiveBuyers()
    }
}
</script>

<style scoped>

</style>